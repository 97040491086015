<template>
  <div class="content-wrapper">
    <div class="d-flex justify-content-between">
      <h1 class="mb-5">Customer Manager</h1>
      <div style="font-size: 1.5rem">
        <b-badge variant="warning">
          <b-icon-star-fill class="mr-2"></b-icon-star-fill> Plugin
        </b-badge>
      </div>
    </div>
    <b-card class="mb-4">
      <b-row class="my-1">
        <b-col lg="3">
          <b-input-group size="sm" class="mb-2">
            <b-input-group-prepend is-text>
              <b-icon icon="search"></b-icon>
            </b-input-group-prepend>
            <b-form-input
              id="filter-input"
              v-model="filter"
              type="search"
              placeholder="Search"
            ></b-form-input>
          </b-input-group>
        </b-col>
        <b-col class="text-lg-right">
          <!-- <b-button
            size="sm"
            class="mb-2 mr-2"
            v-b-toggle.collapse-advancedFilter
            :disabled="advancedFilter_visible"
          >
            <b-icon icon="filter" aria-hidden="true"></b-icon> Advanced Filter
          </b-button> -->

          <!-- <b-button
            :disabled="!filter"
            size="sm"
            class="mb-2 mr-2"
            @click="handleGetGammaByPartitaIva"
          >
            <b-icon icon="download" aria-hidden="true"></b-icon>
            Importa Cliente
          </b-button> -->

          <b-button size="sm" class="mb-2" v-b-toggle.sidebar-backoffice>
            <b-icon icon="plus" aria-hidden="true"></b-icon> Nuovo Customer
          </b-button>
        </b-col>
      </b-row>
    </b-card>
    <b-collapse
      style="overflow: visible"
      id="collapse-advancedFilter"
      v-model="advancedFilter_visible"
      class="mt-2"
    >
      <b-card style="overflow: visible" class="mb-4">
        <div class="d-flex">
          <span>Advanced Filter</span>
          <b-button-close v-b-toggle.collapse-advancedFilter class="ml-auto" />
        </div>
        <b-row class="mt-3">
          <!-- <b-col>
            <label>Ragione sociale</label>
            <b-form-select
              v-model="advancedFilter.ragione_sociale"
              :options="ordineOptions"
            ></b-form-select>
          </b-col>
          <b-col>
            <label>CIE</label>
            <b-form-select
              v-model="advancedFilter.CIE"
              :options="CIEOptions"
            ></b-form-select>
          </b-col> -->
          <b-col>
            <label>Partita IVA</label>
            <b-form-datepicker
              :locale="'it'"
              v-model="advancedFilter.dataInizio"
              class="mb-2"
            ></b-form-datepicker>
          </b-col>
          <b-col>
            <label>Descrizione</label>
            <b-form-datepicker
              :locale="'it'"
              v-model="advancedFilter.dataFine"
              class="mb-2"
            ></b-form-datepicker>
          </b-col>
          <b-col>
            <label>Stato rilevazione</label>
            <b-form-select
              v-model="advancedFilter.stato"
              :options="[
                { value: null, text: 'Seleziona lo stato' },
                { value: 2, text: 'Pubblica' },
                { value: 1, text: 'Interna' },
                { value: 0, text: 'Esculsa' },
              ]"
            ></b-form-select>
          </b-col>
        </b-row>
        <b-row class="justify-content-end mt-4">
          <b-col cols="auto">
            <b-button style="align-self: right">Filtra</b-button>
          </b-col>
        </b-row>
      </b-card>
    </b-collapse>
    <b-card>
      <b-table
        hover
        :items="items"
        :fields="fields"
        :current-page="currentPage"
        :per-page="perPage"
        :filter="filter"
      >
        <template #cell(actions)="row">
          <b-dropdown
            size="sm"
            variant="link"
            toggle-class="text-decoration-none"
            no-caret
          >
            <template #button-content>
              <b-icon-gear-fill></b-icon-gear-fill>
            </template>
            <b-dropdown-item
              href="#"
              v-b-toggle.sidebar-backoffice
              @click="tmpItem = JSON.parse(JSON.stringify(row.item))"
              >Edit</b-dropdown-item
            >

            <b-dropdown-item
              href="#"
              @click="
                tmpItem = row.item;
                showDeleteModal = true;
              "
              >Delete</b-dropdown-item
            >
          </b-dropdown>
        </template>
      </b-table>
      <b-pagination
        v-model="currentPage"
        :total-rows="totalRows"
        :per-page="perPage"
        align="fill"
        size="sm"
        class="mt-4 mb-1"
      ></b-pagination>
    </b-card>
    <!-- </b-overlay> -->
    <b-sidebar
      class="right-sidebar"
      id="sidebar-backoffice"
      right
      shadow
      lazy
      @hidden="resetData"
      @shown="sidebarCustomerVisible = true"
      :visible="sidebarCustomerVisible"
    >
      <div class="content-wrapper my-3 mx-1">
        <div class="mb-5">
          <h4 v-if="tmpItem.id">Edit Customer</h4>
          <h4 v-else>Nuovo Customer</h4>
        </div>
        <b-row class="mt-3">
          <b-col>
            <b-form-group label="Ragione sociale">
              <b-form-input v-model="tmpItem.ragione_sociale" />
            </b-form-group>
          </b-col>
        </b-row>
        <b-row class="mt-3">
          <b-col>
            <b-form-group
              :state="computed_isValidPartitaIVA"
              invalid-feedback="Partita iva non valida"
              label="Partita IVA"
            >
              <b-form-input v-model="tmpItem.partita_iva" />
            </b-form-group>
          </b-col>
        </b-row>

        <b-row class="mt-3">
          <b-col>
            <b-form-group label="Codice cliente Gamma">
              <b-form-input disabled v-model="tmpItem.codiceCliente" />
            </b-form-group>
          </b-col>
        </b-row>

        <b-row class="mt-3">
          <b-col>
            <b-form-group
              :state="computed_isValidCodiceFiscale"
              invalid-feedback="Codice fiscale non valido"
              label="Codice fiscale"
            >
              <b-form-input v-model="tmpItem.codice_fiscale" />
            </b-form-group>
          </b-col>
        </b-row>

        <b-row class="mt-3">
          <b-col>
            <b-form-group label="Descrizione">
              <b-form-input v-model="tmpItem.descrizione" />
            </b-form-group>
          </b-col>
        </b-row>

        <div class="mt-5 mb-5 d-flex justify-content-end align-items-end">
          <b-button
            :disabled="loadingGamma"
            @click="onAllineaConGamma"
            squared
            variant="secondary"
            size="lg"
            class=" big-button mb-2 mr-2"
          >
            {{ loadingGamma ? "Allinea con Gamma..." : "Allinea con Gamma" }}
          </b-button>
          <b-button
            :disabled="loading || !validForm.value"
            squared
            variant="primary"
            size="lg"
            class="big-button mb-2"
            @click="saveItem"
            >{{ loading ? "Salvataggio... " : "Salva" }}</b-button
          >
        </div>
      </div>
    </b-sidebar>
    <b-modal
      v-model="showDeleteModal"
      title="Attenzione!"
      ok-title="Conferma"
      cancel-title="Annulla"
      @hidden="resetData"
      @ok="deleteItem(tmpItem)"
      >Confermi di voler eliminare questo elemento?</b-modal
    >
    <b-modal
      v-model="showGammaConfirmModal"
      title="Conferma aggiornamento da Gamma"
      ok-title="Conferma"
      cancel-title="Annulla"
      @ok="confirmUpdateFromGamma"
    >
      <div>
        <p>
          <strong>Codice Cliente Gamma:</strong> {{ tempGamma.CodiceCliente }}
        </p>
        <p><strong>Partita IVA:</strong> {{ tempGamma.PartitaIva }}</p>
        <p><strong>Ragione Sociale:</strong> {{ tempGamma.RagioneSociale }}</p>
      </div>
    </b-modal>
  </div>
</template>

<script>
// import axios from "axios";
import AxiosService from "../../axiosServices/AxiosService";
import { isValidCodiceFiscale, isValidPartitaIVA } from "@/utils/axerta.js";
export default {
  components: {},
  data() {
    return {
      items: [],
      fields: [
        { key: "id", label: "ID" },
        { key: "ragione_sociale", label: "Ragione Sociale" },
        { key: "codice_fiscale", label: "Codice fiscale" },
        { key: "partita_iva", label: "Partita IVA" },
        { key: "descrizione", label: "Descrizione" },
        { key: "actions", label: "Azioni" },
      ],
      currentPage: 1,
      perPage: 10,
      filter: "",
      advancedFilter_visible: false,
      advancedFilter: {
        ragione_sociale: "",
        partita_iva: "",
        descrizione: "",
      },
      currentItem: null,
      tmpItem: {},
      showDeleteModal: false,
      showGammaConfirmModal: false, // Aggiunto per gestire la visibilità del modal
      sidebarCustomerVisible: false,
      loading: false,
      loadingGamma: false,
      tempGamma: {},
    };
  },
  service: null,
  serviceDel: null,
  created() {
    this.serGET = new AxiosService("DossierManager/GetCustomers");
    this.serPOST = new AxiosService("DossierManager/AddCustomer");
    this.serPUT = new AxiosService("DossierManager/EditCustomer");
    this.serDELETE = new AxiosService("DossierManager/DeleteCustomer");
  },
  mounted() {
    this.loadItems();
  },
  methods: {
    async handleGetGammaByPartitaIva() {
      this.loadingGamma = true;
      const service = new AxiosService(
        "DossierManager/GetGammaCustomer/" + this.tmpItem.partita_iva
      );

      try {
        const res = await service.read();
        this.tempGamma = JSON.parse(res);
        this.showGammaConfirmModal = true; // Mostra il modal per conferma
      } catch (e) {
        console.error("Errore durante il recupero del cliente Gamma:", e);

        // Mostra un toast o un messaggio di errore all'utente
        this.$bvToast.toast("Errore durante il recupero del cliente Gamma.", {
          title: "Errore",
          variant: "danger",
          solid: true,
        });
      } finally {
        this.loadingGamma = false;
      }
    },

    confirmUpdateFromGamma() {
      this.tmpItem.codiceCliente = this.tempGamma.CodiceCliente;
      this.tmpItem.partita_iva = this.tempGamma.PartitaIva.trim();
      this.tmpItem.ragione_sociale = this.tempGamma.RagioneSociale;
    },

    async onAllineaConGamma() {
      await this.handleGetGammaByPartitaIva();
    },
    loadItems() {
      this.items.splice(0);
      this.serGET.read().then((res) => {
        this.items = res;
      });
    },

    resetData() {
      this.currentItem = null;
      this.tmpItem = { text: "", value: 0 };
    },
    async saveItem() {
      this.loading = true;
      if (this.tmpItem.ragione_sociale) {
        this.tmpItem.ragione_sociale = this.tmpItem.ragione_sociale.toUpperCase();
      }
      if (this.tmpItem.id) {
        this.serPUT
          .update(this.tmpItem)
          .then((res) => (res ? this.$successToast() : this.$errorToast()))
          .finally(() => {
            this.loading = false;
            this.sidebarCustomerVisible = false;
            this.loadItems();
          });
      } else {
        this.serPOST
          .create(this.tmpItem)
          .then((res) =>
            res ? this.loadItems() && this.$successToast() : this.$errorToast()
          )
          .finally(() => {
            this.loading = false;
            this.sidebarCustomerVisible = false;
            this.loadItems();
          });
      }
    },

    deleteItem(item) {
      this.serDELETE
        .delete(item.id)
        .then((response) => {
          if (response) {
            this.items = this.items.filter(
              (currentItem) => item.id !== currentItem.value
            );
            this.$successToast();
          } else {
            this.$errorToast();
          }
        })
        .finally(() => {
          this.loadItems();
        });
    },
  },
  computed: {
    totalRows() {
      return this.items ? this.items.length : 0;
    },
    computed_isValidCodiceFiscale() {
      return isValidCodiceFiscale(this.tmpItem.codice_fiscale);
    },
    computed_isValidPartitaIVA() {
      return isValidPartitaIVA(this.tmpItem.partita_iva);
    },
    validForm() {
      if (
        !this.computed_isValidPartitaIVA &&
        !this.computed_isValidCodiceFiscale
      ) {
        return {
          value: false,
          message: "Inserire il Codice fiscale o la Partita iva",
        };
      }
      return { value: true };
    },
  },
  watch: {
    filter: {
      handler() {
        this.currentPage = 1;
      },
    },
  },
};
</script>
